import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "react-phone-number-input/style.css";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import App from "./App";
import "./assets/global.css";
import "react-toastify/dist/ReactToastify.css";

import { Auth0Provider } from "@auth0/auth0-react";
import { UserProvider } from "./layouts/UseContext";

ReactDOM.render(
  <UserProvider>
    <BrowserRouter>
      <Auth0Provider
        redirectUri={`${window.location.origin}/auth`}
        //dev
        // domain="dev-1wj57u20j8tqsa4g.uk.auth0.com"
        // clientId="rgD3fbU1h2sIv1MKAmE7RVBjIMHIycDX"

        //bookdrive-co
        domain={process.env.REACT_APP_AUTH0_DOMAIN || "bookdrive-co.uk.auth0.com"}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || "cmyRBwRmcL6K1jw9SzIFfjekJoKkUm9J"}
      >
        <App />
      </Auth0Provider>
    </BrowserRouter>
  </UserProvider>,
  document.getElementById("root")
);
